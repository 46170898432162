import { mixinPromise } from "@c@/helpers/mixin.promise";
import { DOMAIN_TO_IP } from "@/store/modules/common/domain2ip.module";

export default {
  methods: {
    /**
     * 获取域名ip
     * @param url
     * @param mutation
     * @param actions
     * @returns {Promise | Promise<unknown>}
     */
    domainToIP(url, mutation = null, actions = {
      begin: ()=>{ this.$emit('loading'); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); },
    }){
      return mixinPromise(
        this.$store.dispatch(DOMAIN_TO_IP, {url, mutation}),
        actions
      );
    }
  }
}
