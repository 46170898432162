<template>
  <div class="film-home position-relative">
    <b-overlay :show="platformWhiteListBusy">
      <portlet title="يېڭىدىن سۇپا قوشۇش">
        <template v-slot:body>
          <platform-white-list-form
              ref="whiteList"
              action="edit"
              :model-id="modelId"
              @loading="onLoading"
              @loaded="onLoaded"
              @updating="onLoading"
              @updated="onUpdated"
          />
        </template>
        <template v-slot:foot>
          <b-button id="submit" @click="handleSubmit" variant="primary">
            ساقلاش
          </b-button>
          <b-button :to="{name: 'platform.white-list.home'}" class="ml-2" variant="danger">
            بىكار قىلىش
          </b-button>
        </template>
      </portlet>
    </b-overlay>
  </div>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import PlatformWhiteListForm from "@v@/components/forms/PlatformWhiteListForm";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import msgBoxConfirmMixin from "@m@/common/msgBoxConfirmMixin";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  import {debugConsole} from "@c@/helpers/helpers";

  export default {
    name: "edit",
    components: { Portlet, PlatformWhiteListForm },
    mixins: [formBusyMixin, msgBoxConfirmMixin, sweetAlertMixin],
    data(){
      return {
        platformWhiteListBusy: false,
        form: {}
      };
    },
    computed: {
      modelId(){
        if ( this.$route.hasOwnProperty('params') )
          return this.$route.params.id;
      },
    },
    created() {},
    mounted() {
      BreadCrumbs.addBreadCrumb({title: 'ئاق تىزىملىك باشقۇرۇش', route: {name: 'platform.white-list.home'}});
      BreadCrumbs.addBreadCrumb({title: 'ئاق تىزىملىك ئۇچۇرى يېڭىلاش'});
      if ( this.$refs.hasOwnProperty('whiteList') ){
        this.form = this.$refs['whiteList'];
      }
    },
    methods: {
      handleSubmit(){
        this.form.onSubmit();
      },
      onLoading(){
        this.showBusy('platformWhiteListBusy');
        debugConsole({prefix: 'platformWhiteList', message: 'onLoading'})
      },
      onLoaded({response, state}){
        this.hideBusy('platformWhiteListBusy');
        debugConsole({prefix: 'platformWhiteList', message: `onLoaded: ${state}`});
      },
      onUpdated({response, state}){
        this.hideBusy('platformWhiteListBusy');
        debugConsole({prefix: 'platformWhiteList', message: `onUpdated: ${state}`});
        if ( state === 'success' ){
          this.success({ message: 'ئاق تىزىملىك ئۇچۇرى تەھرىرلەش مۇۋاپىقىيەتلىك بولدى.' });
        }
      },
    }
  }
</script>

<style scoped>
  .btn i {
    padding-left: 0;
  }
</style>
