<template>
  <div class="film-home position-relative">
    <b-overlay :show="platformWhiteListBusy">
      <portlet title="يېڭىدىن ئاق تىزىملىك قوشۇش">
        <template v-slot:body>
          <platform-white-list-form
              ref="whiteList"
              @storing="onStoring"
              @stored="onStored"
          />
        </template>
        <template v-slot:foot>
          <b-button id="submit" @click="handleSubmit" variant="primary">
            ساقلاش
          </b-button>
          <b-button :to="{name: 'platform.white-list.home'}" class="ml-2" variant="danger">
            بىكار قىلىش
          </b-button>
        </template>
      </portlet>
    </b-overlay>
  </div>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import PlatformWhiteListForm from "@v@/components/forms/PlatformWhiteListForm";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import msgBoxConfirmMixin from "@m@/common/msgBoxConfirmMixin";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  import {debugConsole} from "@c@/helpers/helpers";

  export default {
    name: "store",
    components: { Portlet, PlatformWhiteListForm },
    mixins: [formBusyMixin, msgBoxConfirmMixin, sweetAlertMixin],
    data(){
      return {
        platformWhiteListBusy: false,
        form: {}
      };
    },
    computed: {},
    created() {},
    mounted() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({title: 'ئاق تىزىملىك باشقۇرۇش', route: {name: 'platform.white-list.home'}});
      BreadCrumbs.addBreadCrumb({title: 'يېڭىدىن قوشۇش'});
      if ( this.$refs.hasOwnProperty('whiteList') ){
        this.form = this.$refs['whiteList'];
      }
    },
    methods: {
      onStoring(){
        this.showBusy('platformWhiteListBusy');
        debugConsole({prefix: 'platformWhiteList', message: `onStored`});
      },
      onStored({state}){
        this.hideBusy('platformWhiteListBusy');
        debugConsole({prefix: 'platformWhiteList', message: `onStored: ${state}`});
        if ( state === 'success')
          this.$router.replace({name: 'platform.white-list.home'});
      },
      handleSubmit(){
        this.form.onSubmit();
      },
    }
  }
</script>

<style scoped>
  .btn i {
    padding-left: 0;
  }
</style>
