<template>
  <div class="platform-white-list-form-container">
    <error :errors="platformWhiteListError" />
    <b-row>
      <b-col md="2" sm="6">
        <b-form-group id="input-group-state" label="ئوچۇقمۇ ؟" label-for="state">
          <b-form-checkbox id="state" switch size="lg" v-model="formData.state"  />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3" sm="6" >
        <b-form-group id="input-group-title" :label="`سۇپا نامى `" label-for="title" >
          <b-form-input id="title" v-model="formData.title" :state="validateState('title')" type="text" aria-describedby="title-live-feedback" :placeholder="`سۇپا نامىنى كىرگۈزۈڭ `" />
          <b-form-invalid-feedback id="title-live-feedback">
            سۇپا نامىنى كىرگۈزۈڭ
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col md="3" sm="6" >
        <b-form-group id="input-group-url" :label="`سۇپا ئادېرسى`" label-for="url" >
          <b-form-input id="url" class="direction-rtl" v-model="formData.url" lazy :state="validateState('url')" type="url" aria-describedby="url-live-feedback" :placeholder="`سۇپا Api ئادېرسىنى كىرگۈزۈڭ`" />
          <b-form-invalid-feedback id="url-live-feedback">
            سۇپا Api ئادېرسىنى كىرگۈزۈڭ
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col md="3" sm="6" v-if="formData.url">
        <b-form-group label="Token" >
          <b-input-group>
            <b-form-input :state="validateState('token')"  class="direction-rtl" aria-describedby="target-live-feedback" v-model="formData.token" placeholder="token قىممىتىنى ھاسىللاڭ" :readonly="true" />
            <b-input-group-append>
              <b-button class="d-flex justify-content-center align-content-center direction-rtl" @click="onClickGenerateToken"> ھاسىللاش </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="3" sm="6" v-if="formData.url">
        <b-form-group label="IP" >
          <b-input-group>
            <b-form-input :state="validateState('ip')" class="direction-rtl" aria-describedby="target-live-feedback" v-model="formData.ip" placeholder="ip قىممىتىگە ئېرىشىڭ " :readonly="true" />
            <b-input-group-append>
              <b-button class="d-flex justify-content-center align-content-center direction-rtl" @click="onClickGenerateIP"> ئېرىشىش </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="6" md="3">
        <b-form-group id="input-group-9" label=" تەرتىبى" label-for="sort">
          <b-form-input style="width: 100%;" class="direction-rtl" id="sort" min="0" v-model="formData.sort" type="number" placeholder="قۇرۇق قويسىڭىزمۇ بولىدۇ" />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import platformWhiteListMixin from "@m@/platformWhiteList.mixin";
  import domainToIPMixin from "@m@/common/domain2ip.mixin";
  import Error from "@v@/partials/widgets/Error";
  import validateStateMixin from "@m@/common/validateState.mixin";
  import { validationMixin } from "vuelidate";
  import { required } from "vuelidate/lib/validators";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  export default {
    name: "PlatformWhiteListForm",
    components: { Error },
    mixins: [ platformWhiteListMixin, validationMixin, validateStateMixin, sweetAlertMixin, domainToIPMixin ],
    props: {
      action: {
        type: String,
        default: 'create'
      },
      modelId: {
        type: [String, Number],
      },
    },
    validations(){
      let validates = {
        title: { required },
        url: { required },
        token: { required },
        ip: { required },
      };
      return {
        formData: validates
      }
    },
    computed: {},
    created() {
      if (this.action === 'edit'){
        this.getPlatformWhiteList(this.modelId)
          .then(({ data: platform })=>{
            this.formData = platform;
          })
      }
    },
    watch: {
      'formData.url': function(value){
        if ( this.action === 'create' ){
          this.doDomainToIP();
          this.generateToken();
        }
      }
    },
    data(){
      return {
        formData: {
          title: '',
          url: null,
          token: null,
          sort: null,
          ip: null,
          state: true,
        },
      }
    },
    methods: {
      resetFormData(){
        this.formData = {
          title: '',
          url: null,
          token: null,
          sort: null,
          ip: null,
          state: true,
        };
      },
      onSubmit(){
        this.$v.$reset();
        this.$v.formData.$touch();
        if (this.$v.formData.$anyError) {
          return this.warning({message: 'تولدۇرۇشقا تېگىشلىك ئۇچۇرلارنى تۇلۇق تولدۇرۇڭ'});
        }
        if (this.action === 'create'){
          this.storePlatformWhiteList(this.formData)
        }else if ( this.action === 'edit' ){
          this.updatePlatformWhiteList(this.formData)
        }
      },
      onClickGenerateToken(){
        this.generateToken();
      },
      generateToken(){
        const md5 = require('md5');
        const token = md5(this.formData.url + new Date().getTime());
        this.formData.token = token;
        return token;
      },
      onClickGenerateIP(){
        this.doDomainToIP();
      },
      doDomainToIP(){
        this.domainToIP(this.formData.url)
          .then(response=>{
            let { ip } = response.data;
            if ( this.isIP(ip) ){
              this.formData.ip = ip;
            }else {
              return this.warning({message: 'سۇپا ئادېرسىنى توغرا تولدۇرۇڭ'});
            }
          })
          .catch(response=>{
            console.log('catch', response)
          })
      },
      isIP(ip){
        const re =  /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/
        return re.test(ip);
      }
    }
  }
</script>
