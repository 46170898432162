<template>
  <div v-if="visible" class="kt-portlet kt-callout kt-callout--diagonal-bg" :class="bgClass">
    <div class="kt-portlet__body">
      <div class="kt-callout__body">
        <div class="kt-callout__content">
          <h3 class="kt-callout__title">{{ title }}</h3>
          <p class="kt-callout__desc text-justify" v-if="desc">{{ desc }}</p>
        </div>
        <div class="kt-callout__action" v-if="hasActionSlot">
          <slot name="action"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "NoResult",
    props: {
      visible: {
        type: Boolean
      },
      title: {
        type: String,
      },
      desc: {
        type: String,
      },
      background: {
        type: String,
        default: ()=>{
          return 'brand';
        },
        validator: (value)=>{
          return ['success', 'warning', 'danger', 'dark', 'brand', 'primary'].includes(value);
        }
      },
    },
    computed: {
      hasActionSlot() {
        return !!this.$slots["action"];
      },
      bgClass(){
        let bg = `kt-callout--${this.background}`;
        return  {
              // [bg]: this.hasActionSlot,
              [bg]: true,
              "kt-callout--diagonal-bg": this.hasActionSlot
        };
      }
    }
  }
</script>

<style scoped>

</style>
