<template>
  <div class="film-home position-relative">
    <b-overlay :show="platformBusy">
      <no-result :visible="!!platformFilms.length" background="brand" title="بۇ نىمە ئۇچۇرلار؟" :desc="explain" />
      <pagination v-if="platformFilms.length" :meta="platformFilmsMeta" place-holder="فىلىم نامىنى كىرگۈزۈپ ئىزدەڭ" @change-page="onChangePage" @refresh="onRefresh" />
      <b-row class="mb-3" v-if="platformFilms.length">
        <b-col>
          <b-btn-group>
            <b-button variant="primary" aria-controls="film_ids" @click="onClickToggleSelect"> {{ allSelected ? 'بىكار قىلىش' : 'ھەممىسىنى تاللاش'}} </b-button>
            <b-dropdown v-if="film_ids.length" variant="warning" right no-caret>
              <template v-slot:button-content>
                <i class="fa fa-angle-down"></i>
              </template>
              <b-dropdown-item @click="onClickDoTransfer">يۆتكەشنى باشلاش</b-dropdown-item>
            </b-dropdown>
          </b-btn-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col v-for="film in platformFilms" :key="`region_${film.id}`" md="3" sm="6">
          <portlet :title="` `" body-fluid body-class="px-3 pb-3 pt-0" headNoBorder head-class="px-2" head-size="sm">
            <template slot="toolbar">
              <b-checkbox name="film_ids" :value="film.id" v-model="film_ids" />
            </template>
            <template slot="body">
              <div class="kt-widget-item-container">
                <div class="kt-widget kt-widget--user-profile-2">
                  <div class="kt-widget__head">
                    <div class="kt-widget__media">
                      <b-avatar rounded size="50px" text="!" :src="film.preview" />
                    </div>
                    <div class="kt-widget__info">
                      <a class="kt-widget__username">
                        {{ film.title }}
                      </a>
                    </div>
                  </div>
                  <div class="kt-widget__body">
                    <div class="kt-widget__item">
                      <div class="kt-widget__contact">
                        <span class="kt-widget__label">دوۋبەن نۇمۇرى: </span>
                        <span class="kt-widget__data">
                          <b-form-rating variant="primary" precision="2" inline no-border v-model="film.score / 2" readonly /> {{film.score}}
                        </span>
                      </div>
                      <div class="kt-widget__contact">
                        <span class="kt-widget__label">تۈرى: </span>
                        <span class="kt-widget__data">{{ film.categories.length ? film.categories.map(item=> item.title).join(' / ') : 'تۈر تاللىماپسىز' }}</span>
                      </div>
                      <div class="kt-widget__contact">
                        <span class="kt-widget__label">تەۋە رايۇن: </span>
                        <span class="kt-widget__data">{{ film.region ? film.region.title: 'تاللىماپسىز' }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </portlet>
        </b-col>
      </b-row>
      <no-result :visible="!platformFilms.length" background="warning" title="ئۇچۇر تېپىلمىدى" desc="مۇناسىۋەتلىك ئ‍ۇچۇر تېپىلمىدى." />
    </b-overlay>
  </div>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import NoResult from "@v@/partials/content/NoResult";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import pagination from "@v@/components/paginate";
  import platformMixin from "@m@/platform.mixin";
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import msgBoxConfirmMixin from "@m@/common/msgBoxConfirmMixin";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  import {RESET_PLATFORM_FILMS} from "@/store/modules/platform.module";

  export default {
    name: "index",
    components: { Portlet, pagination, NoResult },
    mixins: [formBusyMixin, msgBoxConfirmMixin, sweetAlertMixin, platformMixin],
    data(){
      return {
        film_ids: [],
        allSelected: false,
        platformBusy: false,
        explain: `نۆۋەتتە ئېكراندا كۆرىنىۋاتقىنى بولسا سىز بىلەن سۇپا ئارا ئۇچۇر ئورتاقلىشىش كېلىشىمى ھاسىل قىلغان قارشى تەرەپنىڭ سۇپىسىدىكى كىنو ئۇچۇرلىرى، سىزگە كۆرۈنگەن خالىغان كىنونى ئۆزىڭىزنىڭ سۇپىسىغا يۆتكەپ چىقالايسىز. مەشغۇلات ئاددىي، پەقەت يۆتكىمەكچى بولغان كىنولارنى تاللاپ بولغاندىن كىيىن، مەشغۇلات كونۇپكىسىدىن يۆتكەشنى باشلاش كونۇپكىسىنى باسسىڭىزلا كۇپايە.`
      };
    },
    computed: {
      platformId(){
        if ( this.$route.hasOwnProperty('params') )
          return this.$route.params.id;
      },
    },
    watch: {
      film_ids(val, oldVal){
        if ( val.length === 0 ){
          this.allSelected = false;
        }else {
          this.allSelected = val.length === this.platformFilms.length;
        }
      }
    },
    created() {
      this.handlePlatformFilms(this.platformId)
    },
    mounted() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({title: 'سۇپا باشقۇرۇش', route: {name: 'platform.home'}});
      BreadCrumbs.addBreadCrumb({title: 'ئۇچۇر مەركىزى'});
    },
    methods: {
      onChangePage(e){
        let { title, page } = e;
        this.page = page; this.title = title;
        this.handlePlatformFilms(this.platformId, page, title);
      },
      onRefresh(title){
        this.handlePlatformFilms(this.platformId, 1, title);
      },
      handlePlatformFilms(id, page = 1, title = ''){
        this.showBusy('platformBusy');
        this.getPlatformFilms(this.platformId, page, title)
          .then(response=>{
            this.hideBusy('platformBusy');
          })
          .catch(err=>{
            this.hideBusy('platformBusy');
            let { message } = err.response.data;
            this.error({message});
            this.$store.commit(RESET_PLATFORM_FILMS);
          })
      },
      toggleAllSelected(){
        this.allSelected = ! this.allSelected
      },
      onClickToggleSelect(){
        this.toggleAllSelected();
        let { allSelected } = this;
        if ( allSelected ){
          this.film_ids = this.platformFilms.map(item=> item.id);
        }else {
          this.film_ids = [];
        }
      },
      onClickDoTransfer(){
        this.showBusy('platformBusy');
        this.doTransfer(this.platformId, this.film_ids)
          .then(response=>{
            console.log(response);
            this.hideBusy('platformBusy');
            this.film_ids = [];
            this.success({message: 'يۆتكەش بۇيرىقى ئىجرا بولىۋاتىدۇ، بىر ئازدىن كىيىن كىنو باشقۇرۇش مەركىزىگە كىرىپ تەكشۈرۈپ بېقىڭ'});
          }).catch(err=>{
            this.hideBusy('platformBusy');
            console.log(err.response);
          })
      }
    }
  }
</script>

<style scoped>
  .btn i {
    padding-left: 0;
  }
</style>
