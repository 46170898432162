import { mapGetters } from "vuex";
import { mixinPromise } from "@c@/helpers/mixin.promise";
import { debugConsole } from "@c@/helpers/helpers";
import { GET_PLATFORM_WHITE_LIST, GET_PLATFORM_WHITE_LISTS, STORE_PLATFORM_WHITE_LIST, UPDATE_PLATFORM_WHITE_LIST } from "@/store/modules/platformWhiteList.module";

export default {
  computed: {
    ...mapGetters(['platformWhiteList', 'platformWhiteLists', 'platformWhiteListsMeta', 'platformWhiteListError'])
  },
  methods: {
    getPlatformWhiteList(id, actions = {
      begin: ()=>{ this.$emit('loading'); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); },
    }){
      return mixinPromise(
      this.$store.dispatch(GET_PLATFORM_WHITE_LIST, id),
      actions
      );
    },
    getPlatformWhiteLists(page = 1, title = '', actions = {
      begin: ()=>{ this.showBusy('platformWhiteListBusy'); debugConsole({message: 'loading'}); },
      after: (response, state)=>{ this.hideBusy('platformWhiteListBusy'); debugConsole({message: 'loaded'}); },
    }){
      return mixinPromise(
        this.$store.dispatch(GET_PLATFORM_WHITE_LISTS, {page, title}),
        actions
      );
    },
    storePlatformWhiteList(formData = {}, actions = {
      begin: ()=>{ this.$emit('storing'); },
      after: (response, state)=>{ this.$emit('stored', {response, state}); },
    }){
      return mixinPromise(
        this.$store.dispatch(STORE_PLATFORM_WHITE_LIST, formData),
        actions
      );
    },
    updatePlatformWhiteList(formData = {}, actions = {
      begin: ()=>{ this.$emit('updating'); },
      after: (response, state)=>{ this.$emit('updated', {response, state}); },
    }){
      return mixinPromise(
      this.$store.dispatch(UPDATE_PLATFORM_WHITE_LIST, formData),
      actions
      );
    }
  }
}
