import { mapGetters } from "vuex";
import { mixinPromise } from "@c@/helpers/mixin.promise";
import { debugConsole } from "@c@/helpers/helpers";
import {
  GET_PLATFORM,
  GET_PLATFORMS,
  STORE_PLATFORM,
  UPDATE_PLATFORM,
  DO_PLATFORM_CONNECTION,
  GET_PLATFORM_FILMS, DO_TRANSFER
} from "@/store/modules/platform.module";

export default {
  computed: {
    ...mapGetters(['platforms', 'platformsMeta', 'platform', 'platformError', 'platformFilms', 'platformFilmsMeta'])
  },
  methods: {
    getPlatform(id, actions = {
      begin: ()=>{ this.$emit('loading'); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); },
    }){
      return mixinPromise(
      this.$store.dispatch(GET_PLATFORM, id),
      actions
      );
    },
    getPlatforms(page = 1, title = '', actions = {
      begin: ()=>{ this.showBusy('platformBusy'); debugConsole({message: 'loading'}); },
      after: (response, state)=>{ this.hideBusy('platformBusy'); debugConsole({message: 'loaded'}); },
    }){
      return mixinPromise(
        this.$store.dispatch(GET_PLATFORMS, {page, title}),
        actions
      );
    },
    storePlatform(formData = {}, actions = {
      begin: ()=>{ this.$emit('storing'); },
      after: (response, state)=>{ this.$emit('stored', {response, state}); },
    }){
      return mixinPromise(
        this.$store.dispatch(STORE_PLATFORM, formData),
        actions
      );
    },
    updatePlatform(formData = {}, actions = {
      begin: ()=>{ this.$emit('updating'); },
      after: (response, state)=>{ this.$emit('updated', {response, state}); },
    }){
      return mixinPromise(
      this.$store.dispatch(UPDATE_PLATFORM, formData),
      actions
      );
    },


    doPlatformConnection(id){
      return new Promise((resolve, reject) => {
        this.$store.dispatch(DO_PLATFORM_CONNECTION, id)
        .then(resolve).catch(reject)
      });
    },
    getPlatformFilms(id, page = 1, title = ''){
      return new Promise((resolve, reject) => {
        this.$store.dispatch(GET_PLATFORM_FILMS, {id, page, title})
        .then(resolve).catch(reject)
      });
    },
    doTransfer(platform_id, ids, transfer_type = 'film'){
      return new Promise((resolve, reject) => {
        this.$store.dispatch(DO_TRANSFER, { platform_id, ids, transfer_type })
        .then(resolve).catch(reject)
      });
    },
  }
}
