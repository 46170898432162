<template>
  <div class="platform-form-container">
    <error :errors="platformError" />
    <b-row>
      <b-col md="3" sm="6" >
        <b-form-group id="input-group-title" :label="`سۇپا نامى `" label-for="title" >
          <b-form-input id="title" v-model="formData.title" :state="validateState('title')" type="text" aria-describedby="title-live-feedback" :placeholder="`سۇپا نامىنى كىرگۈزۈڭ `" />
          <b-form-invalid-feedback id="title-live-feedback">
            سۇپا نامىنى كىرگۈزۈڭ
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col md="3" sm="6" >
        <b-form-group id="input-group-url" :label="`سۇپا ئادېرسى`" label-for="url" >
          <b-form-input id="url" class="direction-rtl" v-model="formData.url" :state="validateState('url')" type="text" aria-describedby="url-live-feedback" :placeholder="`سۇپا Api ئادېرسىنى كىرگۈزۈڭ`" />
          <b-form-invalid-feedback id="url-live-feedback">
            سۇپا Api ئادېرسىنى كىرگۈزۈڭ
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col md="3" sm="6">
        <b-form-group id="input-group-url" :label="`Token`" label-for="token" >
          <b-form-input id="token" class="direction-rtl" v-model="formData.token" :state="validateState('token')" type="text" aria-describedby="token-live-feedback" :placeholder="`token قىممىتىنى كىرگۈزۈڭ `" />
          <b-form-invalid-feedback id="token-live-feedback">
            token قىممىتىنى كىرگۈزۈڭ
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col sm="6" md="3">
        <b-form-group id="input-group-9" label=" تەرتىبى" label-for="sort">
          <b-form-input style="width: 100%;" class="direction-rtl" id="sort" min="0" v-model="formData.sort" type="number" placeholder="قۇرۇق قويسىڭىزمۇ بولىدۇ" />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import platformMixin from "@m@/platform.mixin";
  import Error from "@v@/partials/widgets/Error";
  import validateStateMixin from "@m@/common/validateState.mixin";
  import { validationMixin } from "vuelidate";
  import { required } from "vuelidate/lib/validators";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  export default {
    name: "PlatformForm",
    components: { Error },
    mixins: [ platformMixin, validationMixin, validateStateMixin, sweetAlertMixin ],
    props: {
      action: {
        type: String,
        default: 'create'
      },
      modelId: {
        type: [String, Number],
      },
    },
    validations(){
      let validates = {
        title: { required },
        url: { required },
        token: { required },
      };
      return {
        formData: validates
      }
    },
    computed: {},
    created() {
      if (this.action === 'edit'){
        this.getPlatform(this.modelId)
          .then(({ data: platform })=>{
            this.formData = platform;
          })
      }
    },
    mounted() {},
    data(){
      return {
        formData: {
          title: '',
          url: null,
          token: null,
          sort: null
        },
      }
    },
    methods: {
      resetFormData(){
        this.formData = {
          title: '',
          url: null,
          token: null,
          sort: null
        };
      },
      onSubmit(){
        this.$v.$reset();
        this.$v.formData.$touch();
        if (this.$v.formData.$anyError) {
          return this.warning({message: 'تولدۇرۇشقا تېگىشلىك ئۇچۇرلارنى تۇلۇق تولدۇرۇڭ'});
        }
        if (this.action === 'create'){
          this.storePlatform(this.formData)
        }else if ( this.action === 'edit' ){
          this.updatePlatform(this.formData)
        }
      }
    }
  }
</script>
