<template>
  <div class="film-home position-relative">
    <b-overlay :show="platformBusy">
      <no-result :visible="!!platforms.length" background="brand" title="سۇپا دىگەن نىمە؟" :desc="explain" />
      <pagination v-if="platforms.length" :meta="platformsMeta" place-holder="سۇپا نامىنى كىرگۈزۈپ ئىزدەڭ" @change-page="onChangePage" @refresh="onRefresh">
        <b-button slot="action" :to="{name: 'platform.store'}" class="btn btn-label-success btn-sm border-0">
          يېڭىدىن قوشۇش
        </b-button>
      </pagination>
      <b-row>
        <b-col v-for="(platform, index) in platforms" :key="`platform_${platform.id}`" md="3" sm="6">
          <portlet :title="platform.title" body-fluid body-class="px-3 pb-3 pt-0" headNoBorder head-class="px-2" head-size="sm">
            <template slot="toolbar">
              <b-dropdown v-b-tooltip title="مەشغۇلات" toggle-class="text-decoration-none border-0 btn btn-clean btn-sm btn-icon btn-icon-md" no-caret>
                <template v-slot:button-content>
                  <i class="fa fa-ellipsis-v"></i>
                </template>
                <b-dropdown-divider />
                <b-dropdown-item :to="{name: 'platform.edit', params: {id: platform.id}}">
                  <i class="fa fa-info-circle"></i> تەھرىرلەش
                </b-dropdown-item>
                <b-dropdown-item @click="onClickDelete(platform.id)">
                  <i class="fa fa-trash"></i> ئۆچۈرۈش
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <template slot="body">
              <div class="kt-widget-item-container">
                <div class="kt-widget kt-widget--user-profile-2">
                  <div class="kt-widget__body">
                    <div class="kt-widget__item">
                      <div class="kt-widget__contact">
                        <span class="kt-widget__label pr-2"><i class="flaticon-safe-shield-protection"></i></span>
                        <span class="kt-widget__data cursor-default text-justify">{{ platform.url }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="kt-widget__footer d-flex justify-content-around">
                    <b-button @click="onClickConnection(platform.id)" type="button" variant="primary" class="btn-upper"> ئۇلاش </b-button>
                  </div>
                </div>
              </div>
            </template>
          </portlet>
        </b-col>
      </b-row>
      <no-result :visible="!platforms.length" background="warning" title="ئۇچۇر تېپىلمىدى" desc="مۇناسىۋەتلىك سۇپا ئ‍ۇچۇر تېپىلمىدى، يېڭىدىن سۇپا قوشۇڭ." >
        <template slot="action">
          <b-button :to="{name: 'platform.store'}" class="btn-custom btn-bold btn-upper btn-font-sm  btn-warning">سۇپا قوشۇش</b-button>
        </template>
      </no-result>
    </b-overlay>
  </div>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import NoResult from "@v@/partials/content/NoResult";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import pagination from "@v@/components/paginate";
  import platformMixin from "@m@/platform.mixin";
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import msgBoxConfirmMixin from "@m@/common/msgBoxConfirmMixin";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  import commonMethodMixin from "@m@/common/common.method.mixin";
  import {SET_PLATFORM_ERROR} from "@/store/modules/platform.module";

  export default {
    name: "index",
    components: { Portlet, pagination, NoResult },
    mixins: [formBusyMixin, msgBoxConfirmMixin, sweetAlertMixin, platformMixin, commonMethodMixin],
    data(){
      return {
        platformBusy: false,
        explain: `سۇپا دىگىنىمىز، بىز كىنو ئۇچۇرلىرىنى ئۆزىمىزنىڭ سۇپىسىغا كۆچۈرۈشكە بولىدىغان شۇنداقلا بىزنى ئاق تىزىملىككە قوشقان سۇپىلارنى كۆرسىتىدۇ، ناۋادا كېرەك قارشى تەرەپ بىزنى ئاق تىزىملىكتىن چىقىرىۋەتكەن بولسا ياكى ھالىتىمىزنى ئىتىپ قويغان بولسا بىز قارشى تەرەپ سۇپىنىڭ كىنو ئۇچۇرلىرىغا ئېرىشەلمەيمىز.`
      };
    },
    computed: {},
    created() {
      this.getPlatforms();
    },
    mounted() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({title: 'سۇپا باشقۇرۇش', route: {name: 'platform.home'}});
      BreadCrumbs.addBreadCrumb({title: 'بارلىق سۇپىلار'});
    },
    methods: {
      onChangePage(e){
        let { title, page } = e;
        this.page = page; this.title = title;
        this.getPlatforms(page, title);
      },
      onRefresh(title){
        this.getPlatforms(1, title);
      },
      onClickDelete(id){
        this.msgBoxConfirm({
          message: 'مەزكۇر سۇپىنى ئۆچۈرسىڭىز ئەسلىگە قايتۇرالمايسىز، يەنىلا ئۆچۈرۈشنى جەزىملەشتۈرەمسىز ؟',
          confirmCallback: confirm=>{
            if ( confirm ){
              this.deleteModel({
                id, mutationType: SET_PLATFORM_ERROR, resource: 'platforms',
                begin: ()=>{ this.showBusy('platformBusy')},
                after: ()=>{
                  let { title, page } = this;
                  this.getPlatforms(page, title);
                  this.hideBusy('platformBusy');
                },
              })
            }
          }
        });
      },
      onClickConnection(id = null){
        this.showBusy('platformBusy');
        this.doPlatformConnection(id)
          .then(response=>{
            this.hideBusy('platformBusy');
            this.$router.push({name: 'platform.center.home', params: {id}});
          })
          .catch((err)=>{
            this.hideBusy('platformBusy');
            let { message } = err.response.data;
            this.error({message});
          })
      }
    }
  }
</script>

<style scoped>
  .btn i {
    padding-left: 0;
  }
</style>
