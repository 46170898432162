<template>
  <div class="film-home position-relative">
    <b-overlay :show="platformWhiteListBusy">
      <no-result :visible="!!platformWhiteLists.length" background="brand" title="ئاق تىزىملىك دىگەن نىمە؟" :desc="explain" />
      <pagination v-if="platformWhiteLists.length" :meta="platformWhiteListsMeta" place-holder="سۇپا نامىنى كىرگۈزۈپ ئىزدەڭ" @change-page="onChangePage" @refresh="onRefresh">
        <b-button slot="action" :to="{name: 'platform.white-list.store'}" class="btn btn-label-success btn-sm border-0">
          يېڭىدىن قوشۇش
        </b-button>
      </pagination>
      <b-row>
        <b-col v-for="(whiteList, index) in platformWhiteLists" :key="`platform_${whiteList.id}`" md="3" sm="6">
          <portlet :title="whiteList.title" body-fluid body-class="px-3 pb-3 pt-0" headNoBorder head-class="px-2" head-size="sm">
            <template slot="toolbar">
              <b-dropdown v-b-tooltip title="مەشغۇلات" toggle-class="text-decoration-none border-0 btn btn-clean btn-sm btn-icon btn-icon-md" no-caret>
                <template v-slot:button-content>
                  <i class="fa fa-ellipsis-v"></i>
                </template>
                <b-dropdown-text >
                  <b-checkbox switch v-model="whiteList.state" @change="onChangeSwitch( whiteList.id, 'state', $event )"> ھالىتى </b-checkbox>
                </b-dropdown-text>
                <b-dropdown-divider />
                <b-dropdown-item :to="{name: 'platform.white-list.edit', params: {id: whiteList.id}}">
                  <i class="fa fa-info-circle"></i> تەھرىرلەش
                </b-dropdown-item>
                <b-dropdown-item @click="onClickDelete(whiteList.id)">
                  <i class="fa fa-trash"></i> ئۆچۈرۈش
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <template slot="body">
              <div class="kt-widget-item-container">
                <div class="kt-widget kt-widget--user-profile-2">
                  <div class="kt-widget__body">
                    <div class="kt-widget__item">
                      <div class="kt-widget__contact">
                        <span class="kt-widget__label pr-2"><i class="flaticon-safe-shield-protection"></i></span>
                        <span class="kt-widget__data cursor-default text-justify">{{ whiteList.url }}</span>
                      </div>
                      <div class="kt-widget__contact">
                        <span class="kt-widget__label pr-2"><i class="fab fa-expeditedssl"></i></span>
                        <span class="kt-widget__data cursor-default text-justify">{{ whiteList.token }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="kt-widget__footer d-flex justify-content-around">
                    <b-button @click="onClickCopy(whiteList)" type="button" variant="primary" class="btn-upper"> ئۇچۇرلارنى كۆچۈرۈش </b-button>
                  </div>
                </div>
              </div>
            </template>
          </portlet>
        </b-col>
      </b-row>
      <no-result :visible="!platformWhiteLists.length" background="warning" title="ئۇچۇر تېپىلمىدى" desc="مۇناسىۋەتلىك ئ‍ۇچۇر تېپىلمىدى، يېڭىدىن ئاق تىزىملىك قوشۇڭ." >
        <template slot="action">
          <b-button :to="{name: 'platform.white-list.store'}" class="btn-custom btn-bold btn-upper btn-font-sm  btn-warning">ئاق تىزىملىك قوشۇش</b-button>
        </template>
      </no-result>
    </b-overlay>
  </div>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import NoResult from "@v@/partials/content/NoResult";
  import pagination from "@v@/components/paginate";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import msgBoxConfirmMixin from "@m@/common/msgBoxConfirmMixin";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  import platformWhiteListMixin from "@m@/platformWhiteList.mixin";
  import commonMethodMixin from "@m@/common/common.method.mixin";
  import {SET_PLATFORM_WHITE_LIST_ERROR} from "@/store/modules/platformWhiteList.module";

  export default {
    name: "index",
    components: { Portlet, NoResult, pagination },
    mixins: [formBusyMixin, msgBoxConfirmMixin, sweetAlertMixin, platformWhiteListMixin, commonMethodMixin],
    data(){
      return {
        platformWhiteListBusy: false,
        explain: `ئاق تىزىملىك دىگىنىمىز، بىزنىڭ سۇپىدىكى كىنولارنى ئۆزىنىڭ سۇپىسىغا يۆتكىيەلەيدىغان سۇپىلار تىزىملىكىنى كۆرسىتىدۇ. مەشغۇلات كونۇپكىسىدىكى ھالەت تەڭشىكىنى ئىتىش ۋە ئېچىش ئارقىلىق مەزكۇر سۇپىنىڭ بىزنىڭ سۇپىدىكى كىنو ئۇچۇرىغا ئېرىشىش ۋە ئېرىشەلمەسلىكنى تەڭشەشكە بولىدۇ. ئەلۋەتتە بىۋاستە ئۆچۈرۈش مەشغۇلاتى ئارقىلىقمۇ باشقا سۇپىلارنىڭ سۇپىمىزدىكى ئۇچۇرلارغا ئېرىشىشىنى توسالايمىز.`
      };
    },
    computed: {},
    created() {
      this.getPlatformWhiteLists();
    },
    mounted() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({title: 'ئاق تىزىملىك باشقۇرۇش', route: {name: 'platform.white-list.home'}});
      BreadCrumbs.addBreadCrumb({title: 'بارلىق ئاق تىزىملىك'});
    },
    methods: {
      onChangePage(e){
        let { title, page } = e;
        this.page = page; this.title = title;
        this.getPlatformWhiteLists(page, title);
      },
      onRefresh(title){
        this.getPlatformWhiteLists(1, title);
      },
      onClickDelete(id){
        this.msgBoxConfirm({
          message: 'مەزكۇر ئاق تىزىملىكنى ئۆچۈرسىڭىز ئەسلىگە قايتۇرالمايسىز، يەنىلا ئۆچۈرۈشنى جەزىملەشتۈرەمسىز ؟',
          confirmCallback: confirm=>{
            if ( confirm ){
              this.deleteModel({
                id, mutationType: SET_PLATFORM_WHITE_LIST_ERROR, resource: 'platforms-white-lists',
                begin: ()=>{ this.showBusy('platformWhiteListBusy')},
                after: ()=>{
                  let { title, page } = this;
                  this.getPlatformWhiteLists(page, title);
                  this.hideBusy('platformWhiteListBusy');
                },
              })
            }
          }
        });
      },
      onClickCopy(whiteList = {}){
        let info = `${whiteList.title}\r${whiteList.base_url}\r${whiteList.token}`;
        this.$clipboard(info);
        this.success({ message: 'كۆچۈرۈش مۇۋاپىقىيەتلىك بولدى!' });
      },
      onChangeSwitch(id, column, value){
        this.switchColumn({
          resource: 'platforms-white-lists',
          id, column, mutationType: SET_PLATFORM_WHITE_LIST_ERROR,
          begin: ()=>{ this.showBusy('platformWhiteListBusy') },
          after: ()=>{ this.hideBusy('platformWhiteListBusy') },
        });
      },
    }
  }
</script>

<style scoped>
  .btn i {
    padding-left: 0;
  }
</style>
