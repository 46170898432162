import store from "@/store";
import { SET_BREADCRUMB, ADD_BREADCRUMB } from "@/store/modules/breadcrumbs.module";

const BreadCrumbs = {
  init() {},
  setBreadCrumb(payload){
    store.dispatch(SET_BREADCRUMB, payload);
    return this;
  },
  /**
   * addBreadCrumb
   * @param payload
   * { id, title, route }
   */
  addBreadCrumb(payload){
    store.dispatch(ADD_BREADCRUMB, [payload]);
    return this;
  },
};

export default BreadCrumbs;
