export default {
  methods: {
    msgBoxConfirm( { message, title = 'جەزىملەشتۈرەمسىز ؟', confirmCallback = null, confirmTitle = 'شۇنداق', cancelTitle = 'ياق', okVariant='danger' }){
      this.$bvModal.msgBoxConfirm(message, {
        title,
        okVariant,
        okTitle: confirmTitle,
        cancelTitle,
        footerClass: 'p-2',
        hideHeaderClose: true,
        centered: true
      })
      .then(value => {
        confirmCallback && confirmCallback(value);
      })
      .catch(err => {
        console.log(err)
        // An error occurred
      })
    }
  }
}
